body{
  margin:-8px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}

.centered-flex-item{
  display: flex;
  justify-content: center;
  align-items: center;
}

html{
  scroll-behavior: smooth;
}

.italic{
  font-style: italic;
}

.contact-bar{
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s linear;
  column-gap: 20px;
  margin-right: 15px;
  transition: 0.2s ease-in-out;
}

.contact-bar-hidden{
  transform: translateX(2vw);
  opacity: 0;
}

.contact-bar-visible{
  transform: translateX(0);
  opacity: 1;
}

.contact-bar svg{
  height: 30px;
  opacity: 0.5;
}

.contact-bar svg:hover{
  opacity: 1;
}

.contact-bar-hover-item{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-bar-hover-item span{
  position: absolute;
  top:38px;
  visibility: hidden;
  text-wrap: nowrap;
  font-size: 15px ;
  color:black;
  background-color: #4C6E70;
  padding: 7px;
  border-radius: 10px;
}

.contact-bar-hover-item:hover span{
  visibility: visible;
}

@media only screen and (max-width:600px){

.contact-bar{
  margin-right:5px;
  column-gap: 10px;
}

.contact-bar svg{
  height: 18px;
}

.contact-bar-hover-item span{
  top: 28px;
  font-size: 10px;
}
}

@media only screen and (min-width: 1600px){
  .contact-bar svg{
    height: 50px;
  }
  .contact-bar{
    column-gap:20px;
  }

  .contact-bar-hover-item span{
    top:55px;
  }
}

@media only screen and (min-width:2200px){
  .contact-bar svg{
    height: 70px;
  }
  .contact-bar{
    column-gap:40px;
  }

  .contact-bar-hover-item span{
    top:75px;
    font-size: 25px;
  }
}


.header{
  display: flex;
  justify-content: space-between;
  padding:15px 20px;
  position: fixed;
  top:0;
  right:0;
  left:0;
  z-index: 10;
  opacity:0;
  transition: 0.2s ease-in-out;

}

.background-gray{
  background-color:#282e38;
  opacity:1;
}

.header-name{
  font-size: 40px;
  font-weight: bold;
  transition: 0.2s ease-in-out;
}

.header-name-hidden{
  transform: translateX(-2vw);
  opacity: 0;
}

.header-name-visible{
  transform: translateX(0);
  opacity: 1;
}

@media only screen and (max-width: 600px) {

  .header-name{
    font-size: 20px;
  }

  .header-item{
    font-size:13px;
  }
}

@media only screen and (min-width: 1600px){
  .header-name{
    font-size: 60px;
  }
}

@media only screen and (min-width:2200px){
  .header{
    padding: 20px 30px;
  }
  .header-name{
    font-size:80px;
  }
}


.about{
  padding:0px 30px;
  display: flex;
  justify-content: space-evenly;
  background-color: #61758E;
  height: 100vh;
  column-gap: 10px;
}

.about-photo{
  height: 350px;
  width: auto;
  animation: entranceRight 2s;
}

.about-item{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content:  center;
  align-items: center;
}

.about-left-side{
  padding-left:80px;
  row-gap: 15px;
}

.name{
  font-size: 100px;
  font-weight: 800;
  width: fit-content;
  animation-name:entranceLeft;
  animation-duration: 2s;
  text-align: center;
  position: relative;
}


.about-text{
  text-align: center;
  position: relative;
  animation: entranceAboutText 2s;
  animation-fill-mode: backwards;
  opacity: 0.5;
  font-size: 35px;
  animation-delay: 0.1s;
}

@keyframes entranceAboutText {
  from{
    transform: translateX(-10vw);
    opacity: 0;
  }
  to{
    transform: translateX(0);
    opacity: 0.5;
  }
}

.about-svg{
  display:flex;
  column-gap: 15px;
  justify-content: center;
  margin-top: 10px;
  animation:entranceLeft 2s;
  animation-delay: 0.2s;
  animation-fill-mode: backwards;
  position: relative;
}

.about-svg svg{
  height:35px;
  opacity: 0.7;
}

.about-svg svg:hover{
  opacity: 1;
}

.about-svg-item{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-svg-item div{
  position: absolute;
  visibility: hidden;
  text-wrap: nowrap;
  top:40px;
  background-color: #7C8FA6;
  font-size: 14px;
  padding:5px;
  border-radius: 15px;
}


.about-svg-item:hover div{
  visibility: visible;
}

.logo-download{
  fill:#fff;
}

@media only screen and (max-width:700px){
  .about-text{
    font-size: 18px;
  }
  
  .name{
    font-size: 32px;
  }

  .about-item svg{
    height: 30px;
  }

  .about{
    padding:0px 5px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
  }

  .about-left-side{
    padding-left: 0px;
  }

  .about-photo{
    height: 200px;
  }
}

@media only screen and (min-width:701px) and (max-width:900px){
  .name{
    font-size: 50px;
  }

  .about-text{
    font-size:30px;
  }

  .about-photo{
    height: 300px;
  }
}

@media only screen and (min-width:901px) and (max-width:1025px){
  .name{
    font-size:65px;
  }
  
  .about-left-side{
    padding-left:30px;
  }
}


@media only screen and (min-width:1600px){

  .name{
    font-size: 130px;
  }

  .about-text{
    font-size: 50px;
  }

  .about-svg svg{
    height: 40px;
  }

  .about-photo{
    height: 500px;
  }
  
  .about-svg-item div{
    top: 45px;
    font-size: 15px;
  }
  
  .about-left-side{
    padding-left: 90px;
  }
}

@media only screen and (min-width:2200px){
  .name{
    font-size:180px
  }

  .about-text{
    font-size: 80px;
  }

  .about-item svg{
    height: 60px;
  }

  .about-left-side{
    padding-left: 150px;
  }

  .about-photo{
    height: 700px;
  }

  .about-svg-item div{
    top: 68px;
    font-size: 25px;
  }
}

.arrow-container{
  position: absolute;
  bottom: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.arrow-container svg{
  height: 80px;
}

.arrow-link{
  text-decoration: none;
  color: #D3D3D3;
  transform: rotate(90deg);
  display: flex;
}

.link--arrowed .arrow-icon {
  position: relative;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.link--arrowed .arrow-icon--circle {
  -webkit-transition: stroke-dashoffset .3s ease;
  transition: stroke-dashoffset .3s ease;
  stroke-dasharray: 95;
  stroke-dashoffset: 95;
}

.link--arrowed:hover .arrow-icon {
  -webkit-transform: translate3d(10px, 0, 0);
          transform: translate3d(10px, 0, 0);
}
.link--arrowed:hover .arrow-icon--circle {
  stroke-dashoffset: 0;
}

@media only screen and (max-width:600px){
  .arrow-container svg{
    height: 50px;
  }
}

@media only screen and (min-width:1600px){
  .arrow-container svg{
    height:120px;
  }
}

.projects{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:40px 10px;
  background-color: #4c5c70;
}

.projects>h1{
  margin-top:0px;
  animation: entranceLeft 1s;
  font-size: 40px;
}

.project-item{
  animation:entranceLeft 1s;
}

.project-item-h2{
  margin-bottom: 3px;
}

.project-item-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 40px;
}

.project-image-slider{
  overflow: hidden;
  width: 600px;
  position: relative;
  border-radius: 10px;

}

.project-image-slides{
  display: flex;
  transition: 1s;
}


.project-image{
  width: 600px;
  border-radius: 10px;
  margin-right: 10px;
}

.arrow-next{
  position: absolute;
  right:5px;
  top:50%;
  transform: translate(0,-50%);
}

.arrow-prev{
  position:absolute;
  left:5px;
  top:50%;
  transform: translate(0,-50%);
}

.arrow-next svg,.arrow-prev svg{
  height:40px;
  opacity: 0.5;
}

.arrow-next:hover svg, .arrow-prev:hover svg{
  opacity: 1;
}

.project-item-name-tech{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-item-tech-container{
  display: flex;
  column-gap: 5px;
}

.project-item-tech-logo{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-item-tech-logo>svg{
  height: 35px;
  opacity: 0.5;
  position: relative;
}

.project-item-tech-logo>svg:hover{
  opacity: 1;
}

.project-item-tech-logo .logo-text{
  visibility: hidden;
  background-color: #61758E;
  padding: 5px;
  border-radius: 2px;
  position: absolute;
  font-size: 10px;
  top:40px;
}

.project-item-tech-logo:hover .logo-text{
  visibility: visible;
}

.anchor-container{
  display: flex;
  margin-top: 5px;
}

.project-item-a, .project-item-a:visited{
  text-decoration: none;
  color: #D3D3D3;
  margin-right: 5px;
}

.project-item-a:hover{
  color:white;
}

.anchor-div>svg{
  height: 13px;
  fill: #4c5c70;
}

@media only screen and (max-width:700px) {
  .project-image{
    width: 300px;
  }

  .project-image-slider{
    width: 300px;
  }

  .project-image-slides{
    width: 300px;
  }

  .project-item{
    width: 300px;
    font-size: 12px;
  }
  .project-item-tech-logo>svg{
    height: 25px;
  }
}

@media only screen and (min-width:901px) and (max-width:1025px){
  .project-image{
    width: 800px;
  }

  .project-image-slider{
    width: 800px;
  }

  .project-image-slides{
    width: 800px;
  }

  .project-item{
    width: 800px;
    font-size: 12px;
  }
  .project-item-tech-logo>svg{
    height: 25px;
  }
}

@media only screen and (min-width:1600px){
  .projects>h1{
    font-size: 60px;
  }
  
  .project-image{
    width: 1000px;
  }

  .project-image-slider{
    width: 1000px;
  }

  .project-image-slides{
    width: 1000px;
  }

  .project-item{
    width: 1000px;
    font-size: 20px;
  }

  .project-item-tech-logo>svg{
    height: 50px;
  }

  .project-item-tech-logo .logo-text{
    top:60px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 2200px){
  .projects>h1{
    font-size: 80px;
  }
  
  .project-image{
    width: 1200px;
  }

  .project-image-slider{
    width: 1200px;
  }

  .project-image-slides{
    width: 1200px;
  }

  .project-item{
    width: 1200px;
    font-size: 30px;
  }

  .project-item-tech-logo>svg{
    height: 65px;
  }

  .project-item-tech-logo .logo-text{
    top:75px;
    font-size:20px;
  }

  .anchor-div>svg{
    height: 20px;
    fill: #4c5c70;
  }
}

footer{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #61758E;
  color:white;
  height:5rem;
  font-size: 15px;
}

.scroll-show{
  opacity: 1;
  transform: translateX(0);
  transition: all 1s;
}

.scroll-hidden{
  opacity: 0;
  transform: translateX(-5vw);
  transition: all 1s;
}

@keyframes entranceLeft{
  from{
    transform: translateX(-10vw);
    opacity: 0%;
  }
  to{
    transform: translate(0);
    opacity: 100%;
  }
}

@keyframes entranceRight {
  from{
    transform: translate(10vw);
    opacity: 0%;
  }
  to{
    transform: translate(0,inherit);
    opacity: 100%;
  }
}

@keyframes entranceDown {
  from{
    transform: translateY(-2vw);
    opacity: 0%;
  }
  to{
    transform: translate(0);
    opacity: 100%;
  }
  
}
